<template>
  <div class="row py-6" v-for="(c, ci) of items" :key="ci" :id="c.anchor">
    <div class="col-lg-5">
      <h4 class="mb-5" :class="`text-${theme} fw-600`">{{ c.title }}</h4>

      <table class="course-table" v-if="c.table">
        <tr v-for="(row, ri) of c.table.rows" :key="ri">
          <td
            v-for="(td, ti) of row.cells"
            :key="ti"
            :colspan="ti > 0 && row.cells.length <= 2 ? 2 : 1"
          >
            <span
              :class="{
                'fw-600': td.bold,
                [`text-${theme}`]: td.accent || ti === 0,
              }"
            >
              {{ td.content }}
            </span>
          </td>
        </tr>
        <tr v-if="c.check_prices">
          <td></td>
          <td colspan="2">
            <a href="#" @click.prevent="openPriceModal" :class="`text-${theme}`">
              <i class="fa fa-play mr-3"></i>
              {{ c.check_prices }}
            </a>
          </td>
        </tr>
        <tr v-if="c.cta">
          <td></td>
          <td colspan="2">
            <a
              href="#form"
              class="btn btn-block text-center mb-6 mb-lg-0"
              :class="`btn-${theme}`"
            >
              {{ c.cta }}
            </a>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-6 offset-lg-1">
      <h6 :class="`fw-600 text-${theme}`">{{ c.description.subtitle }}</h6>
      <div v-html="c.description.body" />

      <div :class="`card card-outline-${theme} mt-5`" v-if="c.description.card">
        <div class="card-header">
          <h6 class="fw-600 m-0">{{ c.description.card.title }}</h6>
        </div>
        <div class="card-body text-white">{{ c.description.card.body }}</div>
      </div>

      <div class="mt-5" v-if="!(c.boxes && c.boxes.length)">
        <a href="#infoButtons" class="text-white h6">
          <i :class="`fa fa-arrow-up mr-2 text-${theme}`"></i>
          {{ toTopAction }}
        </a>
      </div>
    </div>
    <div class="col-12 mt-5" v-if="c.boxes && c.boxes.length">
      <div class="row">
        <div class="col-md-4" v-for="(b, bi) of c.boxes" :key="bi">
          <div :class="`card card-outline-${theme}`">
            <div class="card-header text-center">
              <h4>{{ b.title }}</h4>
              <small>{{ b.subtitle }}</small>
            </div>

            <div class="card-body">
              <table class="table-unbordered">
                <tr v-for="(p, pi) of b.prices" :key="pi">
                  <td class="text-white text-left">{{ p.title }}</td>
                  <td>...........</td>
                  <td class="text-right">{{ p.price }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-5">
        <a href="#infoButtons" class="text-white h6">
          <i :class="`fa fa-arrow-up mr-2 text-${theme}`"></i>
          {{ toTopAction }}
        </a>
      </div>
    </div>
  </div>

  <Teleport to="#modal-container">
    <PriceModal v-if="showPriceModal" @close="closePriceModal" />
  </Teleport>
</template>

<script>
import PriceModal from "@/components/PriceModal.vue";
import { ref } from "vue";

export default {
  components: {
    PriceModal
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: "primary",
    },
    toTopAction: {
      type: String,
      default: "Go to top",
    },
  },
  setup() {
    const showPriceModal = ref(false);

    const openPriceModal = () => {
      showPriceModal.value = true;
    };

    const closePriceModal = () => {
      showPriceModal.value = false;
    };

    return {
      showPriceModal,
      openPriceModal,
      closePriceModal,
    };
  }
};
</script>
