<template>
  <div>
    <PageHeader fileName="header-8" withTablet withMobile>
      <h3>{{ $lang("title") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('section1.header')" />
          </div>
          <div class="col-lg-6">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("section1.paragraph1") }}
            </h6>

            <p v-html="$lang('section1.paragraph2')" />

            <p>
              {{ $lang("section1.paragraph3") }}
            </p>

            <p>
              {{ $lang("section1.paragraph4") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-dark" id="infoButtons">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="text-tertiary">
              {{ $lang("information.header") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <p>
              {{ $lang("information.paragraph1") }}
            </p>

            <p>
              {{ $lang("information.paragraph2") }}
            </p>

            <p>
              {{ $lang("information.paragraph3") }}
            </p>

            <div class="d-grid gap-3 mt-5">
              <a
                v-for="(c, ci) of $lang('information.buttons')"
                :key="ci"
                :href="c.anchor"
                class="card card-tertiary card-button text-center justify-content-center"
              >
                <div class="card-body" style="margin-top: unset">
                  <b>{{ c.title }}</b>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div
          class="d-flex align-items-center flex-column mt-6"
          v-for="(section, i) of $lang('sections')"
          :key="i"
        >
          <div
            class="card card-outline-tertiary card-rounded px-5"
            :id="section.anchor"
          >
            <div class="card-body text-center">
              <h3 class="m-0">{{ section.title }}</h3>
            </div>
          </div>

          <OurCoursesList
            :items="section.items"
            theme="tertiary"
            :toTopAction="$lang('to_top')"
          />
        </div>
      </div>
    </section>

    <section class="py-7 bg-tertiary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'meet_our_team' }"
            >
              <small class="m-0"> {{ $lang("footer.card1.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0"> {{ $lang("footer.card2.body") }} </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card3.title')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0"> {{ $lang("footer.card3.body") }} </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-7" theme="tertiary" id="form">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import CardButton from "@/components/CardButton.vue";
import OurCoursesList from "@/components/OurCoursesList.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    CardButton,
    OurCoursesList,
  },
};
</script>

<style></style>
